





























import { Component, Vue } from 'vue-property-decorator'
import Common from '../../common/Common'

@Component({
  components: {
  },
})
export default class PrivacyPolicy extends Vue {

  mounted () {
  }
}
